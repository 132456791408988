import { Button, Modal } from "antd";
import axios from "axios";
import React, { useState } from "react";

const UpdatePostsModal = (props: any) => {
  const style = {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
    background: "#404040",
    color: "white",
  };
  const { isUpdatePostModalOpen, selectedUser, setIsUpdatePostModalOpen } =
    props;
  const [isUploadDisabled, setIsUploadDisabled] = useState(false);
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(false);
  const [isUpdateProfileDisabled, setIsUpdateProfileDisabled] = useState(false);

  const buttonStyles = (isDisabled: boolean) => ({
    backgroundColor: isDisabled ? "grey" : "white",
    color: "black",
    border: "1px solid grey",
    cursor: isDisabled ? "not-allowed" : "pointer",
    transition: "background-color 0.3s",
    // padding: "8px",
    borderRadius: "4px",
  });

  async function handleUpdate() {
    console.log("Pulling posts", selectedUser);
    setIsUpdateDisabled(true); // Disable the button
    setTimeout(() => setIsUpdateDisabled(false), 10000);
    const url = `https://dev.thesearchbubble.com/postupdates`;
    // const url = `http://localhost:3001/postupdates`;

    const resThumbnail = axios.post(
      url,
      { username: selectedUser, from: "content" },
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
    // setIsUpdatePostModalOpen(false);
  }

  async function handleUpload() {
    console.log("Pulling stories", selectedUser);
    setIsUploadDisabled(true); // Disable the button
    setTimeout(() => setIsUploadDisabled(false), 10000); // Re-enable after 10 seconds

    const url = `https://dev.thesearchbubble.com/storyupdates`;
    // const url = `http://localhost:3001/storyupdates`;

    const resThumbnail = axios.post(
      url,
      { username: selectedUser, from: "content" },
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
    // setIsUpdatePostModalOpen(false);
  }


  async function handleUpdateProfile() {
    console.log("Updating Profile", selectedUser);
    setIsUpdateProfileDisabled(true); // Disable the button
    setTimeout(() => setIsUpdateProfileDisabled(false), 10000); // Re-enable after 10 seconds

    const url = `https://dev.thesearchbubble.com/profilesetup`;
    // const url = `http://localhost:3001/storyupdates`;

    const resThumbnail = axios.post(
      url,
      { name: selectedUser },
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
    // setIsUpdatePostModalOpen(false);
  }
  function handleCancel() {
    setIsUpdatePostModalOpen(false);
  }
  return (
    <div>
      {" "}
      <Modal
        title={
          <>
            Update Data for{" "}
            <a
              href={`https://www.searchbubble.com/${selectedUser}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {selectedUser}
            </a>{" "}
          </>
        }
        open={isUpdatePostModalOpen}
        // onOk={handleOk}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
        onCancel={handleCancel}
      >
        <div style={{ padding: "20px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <label style={{ marginRight: "10px" }}>Upload Stories:</label>
            <Button
              style={buttonStyles(isUploadDisabled)}
              onClick={handleUpload}
              disabled={isUploadDisabled}
            >
              Upload
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <label style={{ marginRight: "10px" }}>Update Posts:</label>
            <Button
              style={buttonStyles(isUpdateDisabled)}
              onClick={handleUpdate}
              disabled={isUpdateDisabled}
            >
              Update
            </Button>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <label style={{ marginRight: "10px" }}>Update Profile:</label>
            <Button
              style={buttonStyles(isUpdateProfileDisabled)}
              onClick={handleUpdateProfile}
              disabled={isUpdateProfileDisabled}
            >
              Update
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UpdatePostsModal;
