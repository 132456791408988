import { useQuery } from "react-query";
import { supabase } from "../../../Config/SupabaseClient";
// import { getFeedLength } from "../SupabaseServices/ContentDatabase";

export function useFeedFiltersCount(user_id: any, username: any) {
  async function getFeedFilterCount() {
    const filterArray: any = [];

    const { data, error }: any = await supabase
      .from(`${username}`)
      .select("type")
      .eq("user_id", user_id);
    const ActiveFilters = new Set<number>();
    if (data && data.length) {
      data.filter((obj: any) => {
        if (!ActiveFilters.has(obj.type)) {
          ActiveFilters.add(obj.type);
        }
      });
    }
    console.log("ActiveFilters", ActiveFilters);
    // return uniqueSet;
    if (!ActiveFilters) {
      return filterArray;
    } else {
      ActiveFilters.forEach((filter: any) => {
        if (filter === "FEED" || filter === "REELS") {
        } else if (filter === "VIDEO") {
          filterArray.push("Youtube");
        } else if (filter === "PIN") {
          filterArray.push("Pinterest");
        } else if (filter === "TIKTOK") {
          filterArray.push("Tiktok");
        } else if (filter === "PODCAST") {
          filterArray.push("Podcast");
        } else if (filter === "STORY") {
          filterArray.push("Insta Story");
        }else if (filter === "POST") {
          filterArray.push("LinkedIn");
        }else if (filter === "TWEET") {
          filterArray.push("Twitter");
        }
         else if (filter === "HIGHLIGHTS") {
          filterArray.push("Insta Highlights");
        } else if (filter === "MAIL") {
          filterArray.push("Newsletter");
        }
      });
      return filterArray;
    }
  }

  return useQuery(["getFeedFilter", user_id], getFeedFilterCount, {
    refetchOnWindowFocus: true,
  });
}
